$h6m: Helv-6-Medium, sans-serif;
$h5n: Helv-5-Normal, sans-serif;
$geb: Gilroy-ExtraBold, sans-serif;
$dark-text: #272727;
$green-light: #82b64a;
$bg-light: #6cab40;
$bg-common: #eaf9eb;
$title-dark: #4c8c3c;
$title-white: #ffffff;
$text-color: #464646;
$text-accent: #377935;
$bs-dark: 10.625px -2.847px 10px 0px rgba(33, 33, 33, 0.22);

.dib{
	display: inline-block;
	vertical-align: middle;
}

strong{
	font-family: $geb;
}

.button-read{
	display: inline-block;
	margin-top: 10px;
	font-family: $geb;
	font-size: 18px;
	color: rgba(87,87,87,.7);
	background: #f4f4f4;
	text-transform: uppercase;
	padding: 18px 50px;
	text-decoration: none;
	border-radius: 10px;
	box-shadow: 0 5px 10px 0 #82b150;
	&:hover{
		text-decoration: none;
		color: rgba(87,87,87,1);
		box-shadow: inset 0 5px 10px 0 #82b150;
		cursor: pointer;
	}
}

.button-consul{
	display: inline-block;
	margin-top: 10px;
	font-family: $geb;
	font-size: 18px;
	color: rgba(78,150,0,.7);
	background: #f4f4f4;
	text-transform: uppercase;
	padding: 10px 20px;
	text-decoration: none;
	border-radius: 10px;
	box-shadow: 0 5px 10px 0 #82b150;
	&:hover{
		text-decoration: none;
		color: rgba(78,150,0,.7);
		box-shadow: inset 0 5px 10px 0 #82b150;
		cursor: pointer;
	}
}

.button-services{
	display: inline-block;
	margin-top: 10px;
	font-family: $geb;
	font-size: 18px;
	color: rgba(87,87,87,.7);
	background: #f4f4f4;
	text-transform: uppercase;
	padding: 10px 20px;
	text-decoration: none;
	border-radius: 10px;
	box-shadow: 0 5px 10px 0 #82b150;
	&:hover{
		text-decoration: none;
		color: rgba(87,87,87,1);
		cursor: pointer;
		box-shadow: inset 0 5px 10px 0 #82b150;
	}
}

.section-title{
	font-family: $geb;
	font-size: 22px;
	padding-top: 25px;
	padding-bottom: 25px;
	text-transform: uppercase;
	&.dark{
		color: $title-dark;
		span{
			position: relative;
			&::after{
				content: url("../img/icons/title-shield-dark.png");
				position: absolute;
				top: -8px;
				right: -10px;
			}
		}
	}
	&.white{
		color: $title-white;
		span{
			position: relative;
			&::after{
				content: url("../img/icons/title-shield-white.png");
				position: absolute;
				top: -8px;
				right: -10px;
			}
		}
	}
}