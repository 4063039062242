body{
	font-family: $h5n;
	font-size: 16px;
	line-height: 18px;
	background: $bg-common;
}

.top-line{
	background: #eaf9eb;
	position: relative;
	z-index: 3;
	.item{
		max-width: 210px;
		img{
			display: inline-block;
			vertical-align: middle;
		}
	}
	.top-address{
		font-family: $h6m;
		font-size: 14px;
		color: $dark-text;
		line-height: 16px;
		display: inline-block;
		vertical-align: middle;
		max-width: 180px;
	}
	.top-phone{
		display: inline-block;
		vertical-align: middle;
		a{
			display: block;
			font-family: $h6m;
			font-size: 14px;
			line-height: 16px;
			color: $dark-text;
			text-decoration: none;
		}
	}
	.logo{
		margin-right: auto;
		margin-left: 30px;
	}
	.social{
		margin-right: 3px;
		opacity: .8;
		&:hover{
			opacity: 1;
		}
		&:last-child{
			margin-right: 20px;
		}
	}
	.search{
		background: transparent;
		position: relative;
		width: 210px;
		&::before{
			background: #fff;
			background: linear-gradient(135deg, rgb(61, 128, 55) 30%, rgb(0, 0, 0) 90%);
			content: "";
			display: block;
			/* Вместо толщины */
			height: 32px;
			width: 212px;
			position: absolute;
			left: -1px;
			top: -1px;
			z-index: 1;
		}
		input[type="text"]{
			position: relative;
			z-index: 2;
			border: none;
			width: 140px;
			background: #eaf9eb;
			height: 30px;
			color: $green-light;
			&:focus{
				outline: -webkit-focus-ring-color auto 0px;
			}
		}
		.button{
			position: absolute;
			height: 30px;
			top: 0;
			right: 0;
			z-index: 3;
			background: #eaf9eb;
			border: none;
			color: $green-light;
			font-family: $h6m;
			text-transform: uppercase;
			&:focus{
				outline: -webkit-focus-ring-color auto 0px;
			}
			&:hover{
				cursor: pointer;
			}
		}
	}
}

.top-mnu{
	box-shadow: $bs-dark;
	position: relative;
	z-index: 4;
	.hover-menu-drop{
		position: absolute;
		z-index: 2;
		transition: all 0.1s 0.1s;
		-webkit-padding-start: 0px;
		list-style-type: none;
		display: none;
		opacity: 0;
		top: 100%;
		min-width: 215px;
		background: #fff;
	}
	.nav{
		justify-content: center;
		li:last-child{
			a{
				&::after{
					content: '';
					display: none;
				}
			}
		}
		li{
			position: relative;
			z-index: 4;
			&:hover{
				.hover-menu-drop{
					display: block;
					opacity: 1;
				}
			}
			a{
				font-family: $h6m;
				font-size: 18px;
				color: $dark-text;
				padding-top: 15px;
				padding-bottom: 15px;
				position: relative;
				&:hover{
					background: $bg-light;
					color: #fff;
					&::after{
						content: '';
						display: none;
					}
				}
				&.active{
					background: $bg-light;
					color: #fff;
					&::after{
						content: '';
						display: none;
					}
					&::before{
						content: url("../img/icons/menu-shield.png");
						padding-right: 3px;
					}
				}
				&::after{
					content: '';
					position: absolute;
					top: calc(50% - 14px);
					right: -2px;
					display: block;
					background: $bg-light;
					width: 2px;
					height: 25px;
				}
			}
		}
	}
}

.header{
	background-image: url(../img/bg-header.jpg);
	background-size: auto;
	background-position: 60% 0%;
	padding-top: 180px;
	padding-bottom: 135px;
	&-title{
		font-size: 40px;
		line-height: 32px;
		font-family: $geb;
		color: #fff;
	}
	&-pre-title{
		font-size: 40px;
		line-height: 24px;
		color: #111d04;
		font-family: GoodVibesPro, sans-serif;
	}

	&-form{
		text-align: center;
		width: 420px;
		margin-top: 20px;
		.phone{
			background: 0 0;
			border: 2px #fff solid;
			color: #fff;
			padding: 10px 0;
			text-align: center;
			border-radius: 10px;
			font-family: $geb;
			width: 273px;
			&::-webkit-input-placeholder {color: #fff;font-family: $geb;}
			&::-moz-placeholder          {color: #fff;font-family: $geb;}/* Firefox 19+ */
			&:-moz-placeholder           {color: #fff;font-family: $geb;}/* Firefox 18- */
			&:-ms-input-placeholder      {color: #fff;font-family: $geb;}
		}
	}
}

.services{
	position: relative;
	z-index: 5;
	height: 392px;
	margin-top: -35px;
	.section-title{
		background: $bg-common;
		padding-bottom: 10px;
	}
	.section-content{
		background: linear-gradient(to bottom, rgba(244,244,244,1) 0%,rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#ffffff',GradientType=0 );
		box-shadow: $bs-dark;
		.coub{
			margin-left: auto;
			margin-right: -20px;
			width: 535px;
			height: 463px;
			background: url("../img/coub-services.png");
			background-size: cover;
			margin-top: -80px;
			margin-bottom: -80px;
			padding: 45px 90px 45px 70px;
			.h2{
				font-family: $geb;
				color: $title-dark;
			}
			p{
				font-family: $h6m;
				strong{
					font-family: $geb;
				}
			}
		}
		.list-services{
			padding: 25px;
			background: linear-gradient(to bottom, rgba(244,244,244,1) 0%,rgba(234,234,234,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#eaeaea',GradientType=0 );
			box-shadow: 0px 0px 21px 4px rgba(0,0,0,0.35);
			.item{
				display: flex;
				align-items: center;
				width: 45%;
				margin: 5px auto;
				padding-left: 25px;
				min-height: 50px;
				border: 2px $green-light solid;
				border-radius: 10px;
				font-family: $geb;
				color: $dark-text;
			}
		}
	}
}
.ovxh{
	position: relative;
	overflow: hidden;
	height: 180px;
	width: 180px;
	margin-left: auto;
	top: -125px;
	z-index: 9;
	img{
		position: absolute;
		right: -50px;
		z-index: 999;
	}
}

.about-us{
	padding-top: 55px;
	background-image: url("../img/O70LL00.jpg");
	background-repeat: no-repeat;
	background-position: center center;
	.section-title{
		text-align: right;
		padding-right: 85px;
		padding-bottom: 20px;
	}
	.text{
		color: #fff;
		padding: 30px 0px 0px 30px;
		margin-bottom: 40px;
		font-size: 17px;
		span{
			color: #b9d5b7;
		}
	}
	.button-services{
		margin-bottom: 20px;
	}
}

.benefits{
	padding-top: 40px;
	padding-bottom: 40px;
	background: $bg-common;
	.items{
		&-head{
			background-size: 105% 100%;
			height: 200px;
			position: relative;
			&__number{
				font-size: 65px;
				line-height: 65px;
				font-family: $geb;
				position: absolute;
				left: 25px;
				bottom: 70px;
				color: #fff;
			}
			&__title{
				font-size: 18px;
				line-height: 18px;
				font-family: $geb;
				position: absolute;
				right: 50px;
				bottom: 80px;
				max-width: 138px;
			}
		}
		.separator{
			display: none;
			margin-top: auto;
			background-size: 80%;
			background-position-x: 35%;
			background-repeat: no-repeat;
			height: 85px;
		}
		.item{
			display: flex;
			flex-direction: column;
			p{
				font-family: $geb;
				margin-top: -30px;
			}
		}
		.item:nth-child(1){
			.items-head{
				background-image: url(../img/backgrounds/bg-1.png);
				&__title{
					color: $green-light;
					font-size: 24px;
				}
			}
			.separator{
				background-image: url(../img/backgrounds/separator-1.png);
				display: block;
			}
			p{
				color: $green-light;
			}
			&::after{
				content: '';
				background-image: url(../img/backgrounds/separator-4.png);
				background-position: 100% 80%;
				background-size: 75%;
				background-repeat: no-repeat;
				position: absolute;
				top: 0;
				right: 0;
				width: 25px;
				height: 673px;
			}
		}
		.item:nth-child(2){
			.items-head{
				background-image: url(../img/backgrounds/bg-2.png);
				&__title{
					color: $dark-text;
				}
			}
			.separator{
				background-image: url(../img/backgrounds/separator-2.png);
				display: block;
			}
			p{
				color: $dark-text;
			}
			&::after{
				content: '';
				background-image: url(../img/backgrounds/separator-5.png);
				background-position: 100% 80%;
				background-size: 75%;
				background-repeat: no-repeat;
				position: absolute;
				top: 0;
				right: 0;
				width: 25px;
				height: 673px;
			}
		}
		.item:nth-child(3){
			.items-head{
				background-image: url(../img/backgrounds/bg-3.png);
				&__title{
					color: #3b8132;
				}
			}
			p{
				color: #3b8132;
			}
			.separator{
				background-image: url(../img/backgrounds/separator-3.png);
				display: block;
			}
		}
		.item:nth-child(4){
			display: block;
			.items-head{
				background-image: url(../img/backgrounds/bg-3.png);
				&__title{
					color: #3b8132;
				}
			}
			p{
				color: #3b8132;
			}
		}
		.item:nth-child(5){
			display: block;
			.items-head{
				background-image: url(../img/backgrounds/bg-2.png);
				&__title{
					color: #3b8132;
				}
			}
			p{
				color: $dark-text;
			}
		}
		.item:nth-child(6){
			display: block;
			.items-head{
				background-image: url(../img/backgrounds/bg-1.png);
				&__title{
					color: #3b8132;
				}
			}
			p{
				color: $green-light;
			}
		}
	}	
}

.info{
	padding-top: 20px;
	padding-bottom: 45px;
	background-image: url(../img/bg-info.jpg);
	background-size: cover;
	background-position: center bottom;
	background-repeat: no-repeat;
	position: relative;
	z-index: 9;
	.man{
		margin-top: -50px;
	}
	.items{
		.item{
			padding: 10px 45px;
			background: rgba(108, 171, 64, .7);
			border-radius: 10px;
			font-size: 14px;
			font-family: $geb;
			color: #fff;
			height: 47px;
			display: flex;
			align-items: center;
			&:nth-child(2n){
				border-top: 2px #fff solid;
				border-left: 2px #fff solid;
				border-bottom: 2px #fff solid;
			}
			&:nth-child(2n+1){
				border-top: 2px #fff solid;
				border-right: 2px #fff solid;
				border-bottom: 2px #fff solid;
			}
			p{
				margin: 0;
				padding: 0;
			}
		}
	}
	&-form{
		text-align: center;
		margin-top: 20px;
		.phone{
			background: 0 0;
			border: 2px #fff solid;
			color: #fff;
			padding: 10px 0;
			text-align: center;
			border-radius: 10px;
			font-family: $geb;
			width: 45%;
			&::-webkit-input-placeholder {color: #fff;font-family: $geb;}
			&::-moz-placeholder          {color: #fff;font-family: $geb;}/* Firefox 19+ */
			&:-moz-placeholder           {color: #fff;font-family: $geb;}/* Firefox 18- */
			&:-ms-input-placeholder      {color: #fff;font-family: $geb;}
		}
		.button-consul{
			width: auto;
		}
		&__text{
			font-size: 20px;
			text-align: center;
			color: #d5ffc1;
			font-family: $geb;
			margin-top: 1rem;
		}
	}
}

.seminary{
	background-image: url(../img/bg-seminary.png);
	background-size: cover;
	background-position: 55% center;
	position: relative;
	z-index: 10;
	margin-top: -25px;
	overflow: hidden;
	padding-top: 20px;
	&-slider{
		&__item{
			transform: scale(0.8);
			padding-left: 50px;
			.title{
				font-size: 24px;
				line-height: 24px;
				color: #3a7f31;
				font-family: $geb;
				opacity: .5;
			}
			p{
				font-size: 18px;
				line-height: 18px;
				color: #3a7f31;
				font-family: $geb;
			}
		}
		.center{
			.seminary-slider__item{
				padding: 0 0 0 20px;
				transform: scale(1);
				.title{
					font-size: 24px;
					line-height: 24px;
					color: #3a7f31;
					font-family: $geb;
					opacity: .5;
				}
				p{
					font-size: 18px;
					line-height: 18px;
					color: #3a7f31;
					font-family: $geb;
				}
			}
		}
	}
	.owl-nav{
		position: absolute;
		top: calc(50% - 70px);
		right: 0;
		left: 0;
		.owl-prev{
			position: absolute;
			left: 31%;
			color: $green-light;
			opacity: 0.7;
			&:hover{
				opacity: 1;
			}
		}
		.owl-next{
			position: absolute;
			right: 33%;
			color: $green-light;
			opacity: 0.7;
			&:hover{
				opacity: 1;
			}
		}
	}
	.owl-dots{
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		margin-left: -25px;
		padding-bottom: 25px;
		.owl-dot{
			display: inline-block;
			zoom: 1;
			&.active{
				zoom: 1.2;
			}
			span{
				width: 10px;
				height: 10px;
				margin: 5px 7px;
				background: $green-light;
				display: block;
				-webkit-backface-visibility: visible;
				-webkit-transition: opacity .2s ease;
				transition: opacity .2s ease;
				border-radius: 30px;
			}
		}
	}
	.slider-mockup{
		position: absolute;
		bottom: -30px;
		right: 10px;
		overflow: hidden;
		z-index: 11;
	}
}

.consultation{
	background-image: url(../img/bg-consul.jpg);
	background-size: cover;
	background-position: center center;
	position: relative;
	z-index: 8;
	padding-top: 100px;
	padding-bottom: 100px;
	margin-top: -55px;
	&-form{
		margin-top: 20px;
		.phone{
			background: 0 0;
			border: 2px #fff solid;
			color: #fff;
			padding: 10px 0;
			border-radius: 10px;
			text-align: center;
			font-family: $geb;
			width: 280px;
			&::-webkit-input-placeholder {color: #fff;font-family: $geb;}
			&::-moz-placeholder          {color: #fff;font-family: $geb;}/* Firefox 19+ */
			&:-moz-placeholder           {color: #fff;font-family: $geb;}/* Firefox 18- */
			&:-ms-input-placeholder      {color: #fff;font-family: $geb;}
		}
		.button-consul{
			width: 280px;
		}
		&__text{
			font-size: 32px;
			line-height: 32px;
			color: #fff;
			font-family: $geb;
			margin-bottom: 1rem;
		}
		&__pre-text{
			font-size: 20px;
			line-height: 20px;
			color: #fff;
			font-family: $geb;
			margin-top: 1rem;
		}
	}
}

.reviews{
	padding-top: 30px;
	padding-bottom: 50px;
	background: $bg-common;
	position: relative;
	.reviews-slider{
		max-width: 80%;
		margin: 0 auto;
		img{
			height: 100%;
		}
		.image-mask{
			height: 280px;
			width: 280px;
			border: 2px $green-light solid;
			border-radius: 35px;
			overflow: hidden;
			position: relative;
			z-index: 3;
		}
		.item{
			background-color: #e5e5e5;
			border-radius: 35px;
			position: relative;
			margin-top: 20px;
			margin-bottom: 20px;
			box-shadow: 0px 20px 10px -10px rgba(0,0,0,0.75);
			.reviews-text{
				display: flex;
				flex-direction: column;
				justify-content: center;
				max-width: 100%;
				padding: 10px 80px 10px 80px;
				border: 2px #82b64a solid;
				border-radius: 35px;
				z-index: 2;
				background: rgba(255,255,255,0.37);
				&__name{
					font-size: 30px;
					line-height: 30px;
					color: $dark-text;
					font-family: $geb;
				}
				&__date{
					font-size: 16px;
					line-height: 16px;
					font-family: $geb;
					color: $dark-text;
				}
				p{
					margin-top: 1rem;
				}
			}
			.button-services{
				width: 195px;
			}
		}
		.slick-next{
			right: 45px;
			top: 60%;
			&:before{
				content: '▼';
			}
		}
		.slick-prev{
			right: 45px;
			left: auto;
			top: 40%;
			&:before{
				content: '▲';
			}
		}
		.slick-next, .slick-prev{
			z-index: 10;
			&:before{
				color: #70a06a;
				font-size: 35px;
			}
		}
		.slick-dots li button:before{
			font-size: 45px;
			color: $bg-light;
		}
		.slick-dots li.slick-active button:before{
			font-size: 60px;
			color: $title-dark;
		}
	}
	.mockup-1{
		width: 195px;
		position: absolute;
		left: 10%;
		bottom: 0;
	}
	.mockup-2{
		width: 140px;
		position: absolute;
		right: 10%;
		bottom: 0;
	}
}

.footer{
	background-image: url(../img/bg-footer.png);
	padding-top: 30px;
	position: relative;
	z-index: 9;
	.content{
		background-color: rgba(39,39,39,.17);
		margin-bottom: 30px;
		.map{
			border-radius: 35px;
			border: 3px #3a7f31 solid;
			overflow: hidden;
			margin-top: -40px;
			margin-bottom: -40px;
		}
	}
	.copyright{
		padding-top: 20px;
		padding-bottom: 10px;
		background-color: rgba(39,39,39,.8);
		color: #fff;
		position: relative;
		z-index: 99;
		a{
			color: #fff;
			text-decoration: none;
		}
	}
	&-contact{
		flex-direction: column;
		justify-content: center;
		height: 100%;
		color: $dark-text;
		font-family: $geb;
		.item{
			margin-bottom: 10px;
			.dib:first-child{
				min-width: 30px;
				text-align: center;
			}
			.dib:last-child{
				max-width: 200px;
				margin-left: 10px;
				a{
					color: $dark-text;
					text-decoration: none;
				}
			}
			&.social{
				.dib{
					margin-right: 20px;
					margin-left: 0;
				}
			}
		}
	}
	&-man{
		position: absolute;
		bottom: 0;
		right: 15px;
		height: 110%;
	}
}

.owl-carousel{
	.item{
		height: 10rem;
		background: #4DC7A0;
		padding: 1rem;
		h4{
			color: #FFF;
			font-weight: 400;
			margin-top: 0rem;
		}
	}
}

/**
* Inner-page styles
**/

.inner-page{
	.top-mnu{
		background: #fff;
	}
	.section-title{
		&.consalting{
			margin-top: 30px;
			text-transform: none;
		}
	}
	.section-title{
		&.seminary{
			margin-top: 30px;
			margin-bottom: -50px;
			text-transform: none;
		}
	}
	.content{
		p{
			color: $text-color;
			font-family: $h5n;
			span{
				color: $text-accent;
			}
		}
		position: relative;
	}
	.consalting-sep{
		width: 100%;
		margin-top: -145px;
		margin-bottom: -60px;
		position: relative;
		z-index: 4;
	}
	.cta{
		position: relative;
		background-image: url(../img/bg-cta.jpg);
		background-size: cover;
		background-position: center center;
		z-index: 5;
		margin-bottom: 40px;
	}
	.consultation-form{
		margin-top: 200px;
		margin-bottom: 200px;
	}
	.seminary{
		margin-top: 0;
		margin-bottom: 40px;
	}
	.consalting-sep.seminares{
		margin-bottom: 0;
	}
	.seminary-listing{
		margin-bottom: 40px;
		.card{
			border-radius: 10px;
			background-color: #eaeaea;
			border: #82b150 2px solid;
			height: 100%;
			&-body{
				display: flex;
				flex-direction: column;
			}
			&-title{
				font-size: 24px;
				line-height: 24px;
				color: #3a7f31;
				text-transform: uppercase;
				font-family: Gilroy-ExtraBold,sans-serif;
				opacity: .5;
			}
			&-text{
				font-size: 18px;
				line-height: 18px;
				color: #3a7f31;
				font-family: Gilroy-ExtraBold,sans-serif;
			}
			.separator{
				background: #3a7f31;
				margin: auto auto 5px;
				width: 90%;
				height: 2px;
			}
		}
	}
	.about-list{
		.border-list{
			width: calc(98% / 2);
		}
	}
	.poleznaya-info{
		.card{
			background: transparent;
			color: $text-accent;
			margin-bottom: 10px;
			button{
				color: $text-accent;
				font-family: $geb;
				white-space: normal;
			}
		}
	}
	.consalting-sep.pol-info{
		margin-top: 0;
	}

	.reviews-form{
		max-width: 40%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		input{
			width: 50%;
			margin: 10px 0;
		}
		.form-control{
			background: #e5e5e5;
			border-radius: 30px;
			padding: 20px;
			border: #82b64a 2px solid;
			outline: -webkit-focus-ring-color auto 0px;
		}
		textarea{
			width: 100%;
			min-height: 300px;
		}
		.button-consul{
			text-align: center;
			border: #82b64a 2px solid;
		}
	}

	.lektors{
		max-width: 80%;
		margin: 0 auto;
		img{
			height: 100%;
		}
		.image-mask{
			width: 280px;
			height: 280px;
			border: 2px $green-light solid;
			border-radius: 35px;
			overflow: hidden;
			position: relative;
			text-align: center;
			background: #fff;
			z-index: 3;
		}
		.item{
			background-color: #4ea74c;
			border-radius: 35px;
			position: relative;
			margin-top: 20px;
			margin-bottom: 30px;
			.reviews-text{
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 70%;
				padding: 10px 40px 10px 60px;
				border: 2px #82b64a solid;
				border-radius: 35px;
				z-index: 2;
				background: #fff;
				margin-left: auto;
				&__name{
					font-size: 18px;
					line-height: 18px;
					color: #4ea74c;
					font-family: $geb;
					text-transform: uppercase;
				}
				&__date{
					font-size: 16px;
					line-height: 16px;
					margin-top: 10px;
					font-family: $geb;
					color: #4ea74c;
				}
				p{
					margin-bottom: 0.5rem;
					font-size: 14px;
				}
			}
		}
	}

	.consalting-sep.dop-serv{
		margin-top: -130px;
		margin-bottom: 30px;
	}

	.dop-serv-list{
		margin-top: 30px;
		list-style-image: url(../img/icons/menu-shield.png);
		li{
			margin-bottom: 0.2rem;
		}
	}

	.items-dop-serv{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 50px;
		.border-list{
			border: 2px #82b64a solid;
			border-radius: 10px;
			width: calc(91% / 2);
			margin: 10px 1%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 5px 10px;
			font-family: $geb;
		}
		&.width-1{
			.border-list{
				width: 100%;
			}
		}
	}

	.contacts{
		.map{
			border-radius: 35px;
			border: 3px #3a7f31 solid;
			overflow: hidden;
			margin-bottom: 30px;
		}
		.contacts-items{
			flex-direction: column;
			height: 100%;
			color: $dark-text;
			font-family: $geb;
			.item{
				margin-bottom: 10px;
				.dib:first-child{
					min-width: 30px;
					text-align: center;
				}
				.dib:last-child{
					max-width: 200px;
					margin-left: 10px;
					a{
						color: $dark-text;
						text-decoration: none;
					}
				}
				&.social{
					.dib{
						margin-right: 20px;
						margin-left: 0;
					}
				}
			}
		}
	}
	.h3,.h6{
		font-family: $geb;
	}
}

.mobile-menu{
	display: none;
}

.modal-body{
	input.phone{
		background: 0 0;
		border: 2px $text-accent solid;
		color: $text-accent;
		padding: 10px 0;
		text-align: center;
		border-radius: 10px;
		font-family: Gilroy-ExtraBold,sans-serif;
		width: 273px;
	}
}

.blog{
	margin-bottom: 5rem;
	.card{
		strong{
			font-family: $geb;
			&.author{
				color: #000;
			}
		}
		p{
			color: #000;
		}
		a{
			color: #000;
			&:hover{
				text-decoration: none;
				color: #6cab40;
			}
		}
	}
	.paging{
		text-align: center;
		.pageList{
			li{
				display: inline-block;
				a{
					padding: 2px 3px;
					color: #000;
					font-family: $geb;
					&:hover{
						text-decoration: none;
						color: #6cab40;
					}
				}
			}
		}
	}
	.list-group{
		.list-group-item.active{
			z-index: 2;
			color: #fff;
			background-color: #6cab40;
			border-color: #6cab40;
		}
		.list-group-item{
			&.disabled{
				font-family: $geb;
				background-color: transparent;
				border-color: #6cab40;
				&:hover{
					color: #000;
					background-color: transparent;
				}
			}
			.active{
				z-index: 2;
				color: #fff;
				background-color: #6cab40;
				border-color: #6cab40;
			}
			&:hover{
				z-index: 2;
				color: #fff;
				background-color: #6cab40;
				border-color: #6cab40;
			}
		}
	}
}