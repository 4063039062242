/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	.social-search{
		flex-direction: column;
		align-items: stretch;
		margin-left: auto;
		margin-right: auto;
		&.justify-content-end{
			justify-content: space-between!important;
		}
		.item:first-child{
			margin-bottom: 10px;
			display: flex;
			justify-content: space-between;
			.social{
				margin-right: 0;
			}
		}
	}
	.services .section-content .coub{
		height: auto;
	}
	.top-line__contacts{
		flex-wrap: wrap;
		.item{
			max-width: 100%;
			width: 100%;
		}
	}
	.top-line .logo{
		margin-left: auto;
	}
	.top-mnu{
		.nav{
			li{
				a{
					font-size: 14px;
				}
			}
		}
	}
	.services{
		.section-content{
			.coub{
				background-size: 100% 100%;
				padding: 45px 30px 45px 20px;
				width: 100%;
				height: auto;
			}
		}
		.list-services{
			.serv{
				max-height: 245px;
				overflow: scroll;
				.item{
					width: 100%;
				}
			}
		}
		.ovxh{
			display: none;
		}
	}
	.about-us{
		padding-top: 90px;
		background-size: cover;	
	}
	.benefits{
		.items{
			&-head__title{
				font-size: 16px;
				line-height: 16px;
				right: 25px;
			}
			.item:nth-child(1){
				.items-head__title{
					font-size: 20px;
				}
			}
		}
	}
	.seminary{
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 80% 90%;
		&-slider__item{
			padding-left: 30px;
			.title{
				font-size: 18px;
			}
		}
		&-slider{
			.center{
				.seminary-slider__item{
					padding: 0 0 0 15px;
					.title{
						font-size: 20px;
					}
				} 
			} 
		}
		.slider-mockup{
			bottom: 10px;
			img{
				width: 130px;
			}
		}
		.owl-dots{
			margin-left: 0;
		}
	}
	.reviews{
		.reviews-slider{
			max-width: 100%;
			.item{
				.reviews-text{
					max-width: 100%;
				}
			} 
		}
		.mockup-1{
			width: 150px;
			left: 1%;
		}
		.mockup-2{
			right: 5%;
		}
	}
	.footer{
		overflow-x: hidden;
		.footer-man{
			right: -50px;
			height: 80%;
		}
		.map{
			padding-top: -20px;

		}
	}

	/**
	* inner-page styles
	**/

	.inner-page{
		.section-title{
			&.consalting{
				margin-top: 30px;
				margin-bottom: 30px;
				text-transform: none;
			}
		}
		.content{
			margin-bottom: 50px;
		}
		.consalting-sep{
			margin-top: 0;
			margin-bottom: 0;
			display: none;
		}
		.cta{
			background-position: 70% center;
		}
	}

	.inner-page .reviews-form{
		max-width: 80%;
	}
}

@media only screen and (max-width : 768px) {
	.top-line{
		padding: 20px 0;
		.container{
			max-width: 100%;
			flex-wrap: wrap;
		}
		&__contacts{
			max-width: 210px;
			flex-wrap: wrap;
		}
		.logo{
			max-width: 250px;
			img{
				width: 100%;
			}
		}
	}
	.mobile-menu{
		display: block;
	}
	.top-mnu{
		display: none;
		.nav{
			flex-direction: column;
			margin: auto;
			li{
				text-align: center;
				a::after{
					display: none;
				}
			}
		}
	}
	.mobile-menu{
		text-align: center;
		.btn-outline{
			background: transparent;
			border: 1px $text-accent solid;
			font-family: $geb;
		}
		.top-mnu{
			margin-top: 20px;
			display: block;
			background: transparent;
			.innerMenu{
				-webkit-padding-start: 0px;
				list-style-type: none;
				background: #fff;
			}
		}
	}
	.header-title{
		font-size: 28px;
		text-align: right;
	}
	.header-pre-title{
		font-size: 39px;
		text-align: right;
	}
	.header-form{
		width: 100%;
		text-align: right;
	}
	.header{
		background-size: cover;
		background-position: 50% 0;
		padding-top: 70px;
		padding-bottom: 70px;
	}
	.about-us{
		padding-top: 30px;
		.container{
			max-width: 100%;
			.text{
				font-size: 14px;
				padding: 10px 0 0 0px;
			}
		}
	}
	.services{
		height: auto;
	}
	.services .section-content .coub{
		margin-bottom: 50px;
	}
	.benefits{
		.items{
			.item:nth-child(1)::after{
				display: none;
			}
			.item:nth-child(2)::after{
				display: none;
			}
			.item:nth-child(4){
				display: flex;
				.separator{
					background-image: url(../img/backgrounds/separator-3.png);
					display: block;
				}
			}
		}
		.items-head__title{
			right: 45px;
		}
	}
	.info{
		img.w-100{
			width: auto!important;
			margin-left: auto;
			margin-right: auto;
		}
		.section-title{
			text-align: center;
		}
	}
	.seminary{
		background-size: cover;
		background-position: 75% 90%;
	}
	.seminary-slider{
		.owl-item.active{
			.seminary-slider__item{
				padding: 0 0 0 20px;
				transform: scale(1);
				.title{
					font-size: 24px;
					line-height: 24px;
					color: #3a7f31;
					font-family: $geb;
					opacity: .5;
				}
				p{
					font-size: 18px;
					line-height: 18px;
					color: #3a7f31;
					font-family: $geb;
				}
			}
		}

		.owl-item.active + .owl-item.active{
			.seminary-slider__item{
				transform: scale(0.8);
				padding-left: 50px;
				.title{
					font-size: 24px;
					line-height: 24px;
					color: #3a7f31;
					font-family: $geb;
					opacity: .5;
				}
				p{
					font-size: 18px;
					line-height: 18px;
					color: #3a7f31;
					font-family: $geb;
				}
			}
		}
		.owl-nav{
			.owl-prev{
				left: -15px;
			}
			.owl-next{
				left: 48%;
			}
		}
		.owl-dots{
			text-align: left;
			margin-left: 20%;
		}
	}
	.consultation-form__text{
		font-size: 25px;
		line-height: 25px;
	}
	.reviews .reviews-slider{
		.image-mask{
			width: 220px;
			height: 220px;
			margin-top: 30px;
			margin-bottom: 30px;
		}
		.reviews-text{
			&__name{
				display: inline-block;
			}
			&__date{
				display: inline-block;
			}
			p{
				font-size: 14px;
			}
		}
	}

	.inner-page{
		.poleznaya-info{
			.card{
				text-align: center;
			}
			.blog{
				.card{
					text-align: left;
				}
			}
		}
	}
}

@media only screen and (max-width : 576px) {
	.top-line{
		padding: 0;
		.container{
			flex-wrap: wrap;
		}
		&__contacts{
			max-width: 100%;
			margin: 10px auto;
		}
		.logo{
			margin: 10px auto;
		}
		.social-search{
			margin: 10px auto;
		}
	}
	.header{
		background-position: 100% 0;
		&-title{
			text-align: center;
		}
		&-pre-title{
			text-align: center;
			color: $bg-common;
		}
		&-form{
			text-align: center;
		}
	}
	.services{
		height: auto;
		.section-title.dark{
			text-align: center;
			span{
				margin-left: 0;
			}
		}
		.section-content{
			.list-services{
				.serv{
					max-height: 100%;
				}
			}
			.coub{
				padding: 30px 30px 50px 30px;
				margin-right: auto;
				margin-bottom: 0;	
			}
		}
	}
	.about-us{
		background-position: left center;
		.section-title{
			text-align: center;
		}
		.text-right{
			text-align: center!important;
		}
	}
	.benefits{
		.items{
			.item{
				margin-bottom: 20px;
				&:nth-child(5) .separator{
					background-image: url(../img/backgrounds/separator-2.png);
					display: block;
				}
			}
		}
	}
	.info{
		img.w-100{
			width: 100% !important;
		}
	}
	.seminary{
		background-image: url(../img/bg-seminary-sm.png);
		background-position: center center;
		background-size: 100% 100%;
		&-slider{
			.owl-nav{
				.owl-prev{
					left: 0;
				}
				.owl-next{
					right: 0;
					left: auto;
				}
			}
			.owl-item.active{
				.seminary-slider__item{
					padding: 0 20px;
				}
			}
			.owl-dots{
				text-align: center;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	.consultation{
		background-position: 68% center;
		&-form{
			text-align: center;
		}
	}
	.reviews{
		.reviews-slider{
			.item{
				display: block!important;
				.image-mask{
					width: 80%;
					height: 80%;
					margin: auto;
					display: none;
				}
				.reviews-text{
					max-width: 100%;
					position: relative;
					top: 0;
					right: 0;
					left: 0;
					bottom: 0;
					align-text: justify;
					padding: 20px 40px 20px 20px;
				}
			}
			.slick-prev{
				right: 25px;
			}
			.slick-next{
				right: 25px;
			}
		}
		.mockup-1{
			display: none;
		}
		.mockup-2{
			display: none;
		}
	}
	.footer{
		.content{
			.map{
				margin-bottom: 20px;
				margin-top: 20px;
			}
			.footer-contact{
				align-items: center;
			}
		}
		.footer-man{
			display: none;
		}
	}
	.info .items .item{
		height: auto;
	}

	.inner-page .items-dop-serv .border-list{
		width: calc(96% / 2);
	}
	.inner-page .reviews-form{
		max-width: 100%;
	}
}

@media only screen and (max-width : 480px) {
	.inner-page .items-dop-serv .border-list{
		width: 100%;
		margin: 10px auto;
	}
}