
/* Libs Style */
@import "../../bower_components/bootstrap/scss/bootstrap-reboot";
@import "../../bower_components/bootstrap/scss/utilities";
@import "../../bower_components/bootstrap/scss/grid";
@import "../../bower_components/bootstrap/scss/dropdown";
@import "../../bower_components/bootstrap/scss/nav";
@import "../../bower_components/bootstrap/scss/type";
@import "../../bower_components/bootstrap/scss/buttons";
@import "../../bower_components/bootstrap/scss/card";
@import "../../bower_components/bootstrap/scss/transitions";
@import "../../bower_components/bootstrap/scss/modal";
@import "../../bower_components/bootstrap/scss/close";
@import "../../bower_components/bootstrap/scss/list-group";
@import "../../bower_components/bootstrap/scss/variables";
@import "../../bower_components/owl.carousel/src/scss/owl.carousel";
@import "../../bower_components/owl.carousel/src/scss/owl.theme.default";
@import "../../bower_components/font-awesome/css/font-awesome.css";
@import "../../bower_components/animate.css/animate.css";
@import "../../bower_components/slick-carousel/slick/slick.scss";
@import "../../bower_components/slick-carousel/slick/slick-theme.scss";



/* Common style */

@import "partials/fonts";
@import "partials/variables";
@import "partials/app";
@import "partials/media";
