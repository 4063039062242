@font-face {
	font-family: Helv-6-Medium; /* Имя шрифта */
	src: url(../fonts/Helv-6-Medium.ttf); /* Путь к файлу со шрифтом */
}
@font-face {
	font-family: Helv-5-Normal; /* Имя шрифта */
	src: url(../fonts/Helv-5-Normal.ttf); /* Путь к файлу со шрифтом */
}
@font-face {
	font-family: Gilroy-ExtraBold; /* Имя шрифта */
	src: url(../fonts/Gilroy-ExtraBold.ttf); /* Путь к файлу со шрифтом */
}
@font-face {
	font-family: GoodVibesPro; /* Имя шрифта */
	src: url(../fonts/GoodVibesPro.ttf); /* Путь к файлу со шрифтом */
}