@charset "UTF-8";
/* Libs Style */
@import url(../../bower_components/font-awesome/css/font-awesome.css);
@import url(../../bower_components/animate.css/animate.css);
html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #044e5b;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #010f12;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #044e5b !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #02242a !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #044e5b !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #044e5b !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #02242a !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; } }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #044e5b; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
    background-color: transparent; }

.show > a {
  outline: 0; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868e96; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #e9ecef #e9ecef #ddd; }
    .nav-tabs .nav-link.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }
  .nav-pills .nav-link.active,
  .show > .nav-pills .nav-link {
    color: #fff;
    background-color: #044e5b; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(4, 78, 91, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: .65; }
  .btn:active, .btn.active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #044e5b;
  border-color: #044e5b; }
  .btn-primary:hover {
    color: #fff;
    background-color: #022f36;
    border-color: #02242a; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(4, 78, 91, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #044e5b;
    border-color: #044e5b; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #022f36;
    background-image: none;
    border-color: #02242a; }

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #727b84;
    background-image: none;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    background-color: #218838;
    background-image: none;
    border-color: #1e7e34; }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    background-color: #138496;
    background-image: none;
    border-color: #117a8b; }

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #111;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    background-color: #e0a800;
    background-image: none;
    border-color: #d39e00; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    background-color: #c82333;
    background-image: none;
    border-color: #bd2130; }

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #111;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    background-color: #e2e6ea;
    background-image: none;
    border-color: #dae0e5; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    background-color: #23272b;
    background-image: none;
    border-color: #1d2124; }

.btn-outline-primary {
  color: #044e5b;
  background-color: transparent;
  background-image: none;
  border-color: #044e5b; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #044e5b;
    border-color: #044e5b; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 3px rgba(4, 78, 91, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #044e5b;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #044e5b;
    border-color: #044e5b; }

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:active, .btn-outline-info.active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:active, .btn-outline-warning.active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:active, .btn-outline-light.active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:active, .btn-outline-dark.active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }

.btn-link {
  font-weight: normal;
  color: #044e5b;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active {
    border-color: transparent;
    box-shadow: none; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #010f12;
    text-decoration: underline;
    background-color: transparent; }
  .btn-link:disabled {
    color: #868e96; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: 15px;
      margin-left: 15px; } }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group .card {
      flex: 1 0 0%; }
      .card-group .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group .card:first-child .card-img-top {
          border-top-right-radius: 0; }
        .card-group .card:first-child .card-img-bottom {
          border-bottom-right-radius: 0; }
      .card-group .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group .card:last-child .card-img-top {
          border-top-left-radius: 0; }
        .card-group .card:last-child .card-img-bottom {
          border-bottom-left-radius: 0; }
      .card-group .card:not(:first-child):not(:last-child) {
        border-radius: 0; }
        .card-group .card:not(:first-child):not(:last-child) .card-img-top,
        .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
          border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:focus, .list-group-item:hover {
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868e96;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #044e5b;
    border-color: #044e5b; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #02292f;
  background-color: #b9cdd1; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #02292f; }
  a.list-group-item-primary:focus, a.list-group-item-primary:hover,
  button.list-group-item-primary:focus,
  button.list-group-item-primary:hover {
    color: #02292f;
    background-color: #aac2c7; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    color: #fff;
    background-color: #02292f;
    border-color: #02292f; }

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e; }
  a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover {
    color: #464a4e;
    background-color: #cfd2d6; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    color: #fff;
    background-color: #464a4e;
    border-color: #464a4e; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

a.list-group-item-success,
button.list-group-item-success {
  color: #155724; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #155724;
    background-color: #b1dfbb; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460; }
  a.list-group-item-info:focus, a.list-group-item-info:hover,
  button.list-group-item-info:focus,
  button.list-group-item-info:hover {
    color: #0c5460;
    background-color: #abdde5; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404; }
  a.list-group-item-warning:focus, a.list-group-item-warning:hover,
  button.list-group-item-warning:focus,
  button.list-group-item-warning:hover {
    color: #856404;
    background-color: #ffe8a1; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #721c24;
    background-color: #f1b0b7; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

a.list-group-item-light,
button.list-group-item-light {
  color: #818182; }
  a.list-group-item-light:focus, a.list-group-item-light:hover,
  button.list-group-item-light:focus,
  button.list-group-item-light:hover {
    color: #818182;
    background-color: #ececf6; }
  a.list-group-item-light.active,
  button.list-group-item-light.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21; }
  a.list-group-item-dark:focus, a.list-group-item-dark:hover,
  button.list-group-item-dark:focus,
  button.list-group-item-dark:hover {
    color: #1b1e21;
    background-color: #b9bbbe; }
  a.list-group-item-dark.active,
  button.list-group-item-dark.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #869791;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Common style */
@font-face {
  font-family: Helv-6-Medium;
  /* Имя шрифта */
  src: url(../fonts/Helv-6-Medium.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: Helv-5-Normal;
  /* Имя шрифта */
  src: url(../fonts/Helv-5-Normal.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: Gilroy-ExtraBold;
  /* Имя шрифта */
  src: url(../fonts/Gilroy-ExtraBold.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: GoodVibesPro;
  /* Имя шрифта */
  src: url(../fonts/GoodVibesPro.ttf);
  /* Путь к файлу со шрифтом */ }

.dib {
  display: inline-block;
  vertical-align: middle; }

strong {
  font-family: Gilroy-ExtraBold, sans-serif; }

.button-read {
  display: inline-block;
  margin-top: 10px;
  font-family: Gilroy-ExtraBold, sans-serif;
  font-size: 18px;
  color: rgba(87, 87, 87, 0.7);
  background: #f4f4f4;
  text-transform: uppercase;
  padding: 18px 50px;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 #82b150; }
  .button-read:hover {
    text-decoration: none;
    color: #575757;
    box-shadow: inset 0 5px 10px 0 #82b150;
    cursor: pointer; }

.button-consul {
  display: inline-block;
  margin-top: 10px;
  font-family: Gilroy-ExtraBold, sans-serif;
  font-size: 18px;
  color: rgba(78, 150, 0, 0.7);
  background: #f4f4f4;
  text-transform: uppercase;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 #82b150; }
  .button-consul:hover {
    text-decoration: none;
    color: rgba(78, 150, 0, 0.7);
    box-shadow: inset 0 5px 10px 0 #82b150;
    cursor: pointer; }

.button-services {
  display: inline-block;
  margin-top: 10px;
  font-family: Gilroy-ExtraBold, sans-serif;
  font-size: 18px;
  color: rgba(87, 87, 87, 0.7);
  background: #f4f4f4;
  text-transform: uppercase;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 #82b150; }
  .button-services:hover {
    text-decoration: none;
    color: #575757;
    cursor: pointer;
    box-shadow: inset 0 5px 10px 0 #82b150; }

.section-title {
  font-family: Gilroy-ExtraBold, sans-serif;
  font-size: 22px;
  padding-top: 25px;
  padding-bottom: 25px;
  text-transform: uppercase; }
  .section-title.dark {
    color: #4c8c3c; }
    .section-title.dark span {
      position: relative; }
      .section-title.dark span::after {
        content: url("../img/icons/title-shield-dark.png");
        position: absolute;
        top: -8px;
        right: -10px; }
  .section-title.white {
    color: #ffffff; }
    .section-title.white span {
      position: relative; }
      .section-title.white span::after {
        content: url("../img/icons/title-shield-white.png");
        position: absolute;
        top: -8px;
        right: -10px; }

body {
  font-family: Helv-5-Normal, sans-serif;
  font-size: 16px;
  line-height: 18px;
  background: #eaf9eb; }

.top-line {
  background: #eaf9eb;
  position: relative;
  z-index: 3; }
  .top-line .item {
    max-width: 210px; }
    .top-line .item img {
      display: inline-block;
      vertical-align: middle; }
  .top-line .top-address {
    font-family: Helv-6-Medium, sans-serif;
    font-size: 14px;
    color: #272727;
    line-height: 16px;
    display: inline-block;
    vertical-align: middle;
    max-width: 180px; }
  .top-line .top-phone {
    display: inline-block;
    vertical-align: middle; }
    .top-line .top-phone a {
      display: block;
      font-family: Helv-6-Medium, sans-serif;
      font-size: 14px;
      line-height: 16px;
      color: #272727;
      text-decoration: none; }
  .top-line .logo {
    margin-right: auto;
    margin-left: 30px; }
  .top-line .social {
    margin-right: 3px;
    opacity: .8; }
    .top-line .social:hover {
      opacity: 1; }
    .top-line .social:last-child {
      margin-right: 20px; }
  .top-line .search {
    background: transparent;
    position: relative;
    width: 210px; }
    .top-line .search::before {
      background: #fff;
      background: linear-gradient(135deg, #3d8037 30%, black 90%);
      content: "";
      display: block;
      /* Вместо толщины */
      height: 32px;
      width: 212px;
      position: absolute;
      left: -1px;
      top: -1px;
      z-index: 1; }
    .top-line .search input[type="text"] {
      position: relative;
      z-index: 2;
      border: none;
      width: 140px;
      background: #eaf9eb;
      height: 30px;
      color: #82b64a; }
      .top-line .search input[type="text"]:focus {
        outline: -webkit-focus-ring-color auto 0px; }
    .top-line .search .button {
      position: absolute;
      height: 30px;
      top: 0;
      right: 0;
      z-index: 3;
      background: #eaf9eb;
      border: none;
      color: #82b64a;
      font-family: Helv-6-Medium, sans-serif;
      text-transform: uppercase; }
      .top-line .search .button:focus {
        outline: -webkit-focus-ring-color auto 0px; }
      .top-line .search .button:hover {
        cursor: pointer; }

.top-mnu {
  box-shadow: 10.625px -2.847px 10px 0px rgba(33, 33, 33, 0.22);
  position: relative;
  z-index: 4; }
  .top-mnu .hover-menu-drop {
    position: absolute;
    z-index: 2;
    transition: all 0.1s 0.1s;
    -webkit-padding-start: 0px;
    list-style-type: none;
    display: none;
    opacity: 0;
    top: 100%;
    min-width: 215px;
    background: #fff; }
  .top-mnu .nav {
    justify-content: center; }
    .top-mnu .nav li:last-child a::after {
      content: '';
      display: none; }
    .top-mnu .nav li {
      position: relative;
      z-index: 4; }
      .top-mnu .nav li:hover .hover-menu-drop {
        display: block;
        opacity: 1; }
      .top-mnu .nav li a {
        font-family: Helv-6-Medium, sans-serif;
        font-size: 18px;
        color: #272727;
        padding-top: 15px;
        padding-bottom: 15px;
        position: relative; }
        .top-mnu .nav li a:hover {
          background: #6cab40;
          color: #fff; }
          .top-mnu .nav li a:hover::after {
            content: '';
            display: none; }
        .top-mnu .nav li a.active {
          background: #6cab40;
          color: #fff; }
          .top-mnu .nav li a.active::after {
            content: '';
            display: none; }
          .top-mnu .nav li a.active::before {
            content: url("../img/icons/menu-shield.png");
            padding-right: 3px; }
        .top-mnu .nav li a::after {
          content: '';
          position: absolute;
          top: calc(50% - 14px);
          right: -2px;
          display: block;
          background: #6cab40;
          width: 2px;
          height: 25px; }

.header {
  background-image: url(../img/bg-header.jpg);
  background-size: auto;
  background-position: 60% 0%;
  padding-top: 180px;
  padding-bottom: 135px; }
  .header-title {
    font-size: 40px;
    line-height: 32px;
    font-family: Gilroy-ExtraBold, sans-serif;
    color: #fff; }
  .header-pre-title {
    font-size: 40px;
    line-height: 24px;
    color: #111d04;
    font-family: GoodVibesPro, sans-serif; }
  .header-form {
    text-align: center;
    width: 420px;
    margin-top: 20px; }
    .header-form .phone {
      background: 0 0;
      border: 2px #fff solid;
      color: #fff;
      padding: 10px 0;
      text-align: center;
      border-radius: 10px;
      font-family: Gilroy-ExtraBold, sans-serif;
      width: 273px;
      /* Firefox 19+ */
      /* Firefox 18- */ }
      .header-form .phone::-webkit-input-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
      .header-form .phone::-moz-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
      .header-form .phone:-moz-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
      .header-form .phone:-ms-input-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }

.services {
  position: relative;
  z-index: 5;
  height: 392px;
  margin-top: -35px; }
  .services .section-title {
    background: #eaf9eb;
    padding-bottom: 10px; }
  .services .section-content {
    background: linear-gradient(to bottom, #f4f4f4 0%, white 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#ffffff',GradientType=0 );
    box-shadow: 10.625px -2.847px 10px 0px rgba(33, 33, 33, 0.22); }
    .services .section-content .coub {
      margin-left: auto;
      margin-right: -20px;
      width: 535px;
      height: 463px;
      background: url("../img/coub-services.png");
      background-size: cover;
      margin-top: -80px;
      margin-bottom: -80px;
      padding: 45px 90px 45px 70px; }
      .services .section-content .coub .h2 {
        font-family: Gilroy-ExtraBold, sans-serif;
        color: #4c8c3c; }
      .services .section-content .coub p {
        font-family: Helv-6-Medium, sans-serif; }
        .services .section-content .coub p strong {
          font-family: Gilroy-ExtraBold, sans-serif; }
    .services .section-content .list-services {
      padding: 25px;
      background: linear-gradient(to bottom, #f4f4f4 0%, #eaeaea 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#eaeaea',GradientType=0 );
      box-shadow: 0px 0px 21px 4px rgba(0, 0, 0, 0.35); }
      .services .section-content .list-services .item {
        display: flex;
        align-items: center;
        width: 45%;
        margin: 5px auto;
        padding-left: 25px;
        min-height: 50px;
        border: 2px #82b64a solid;
        border-radius: 10px;
        font-family: Gilroy-ExtraBold, sans-serif;
        color: #272727; }

.ovxh {
  position: relative;
  overflow: hidden;
  height: 180px;
  width: 180px;
  margin-left: auto;
  top: -125px;
  z-index: 9; }
  .ovxh img {
    position: absolute;
    right: -50px;
    z-index: 999; }

.about-us {
  padding-top: 55px;
  background-image: url("../img/O70LL00.jpg");
  background-repeat: no-repeat;
  background-position: center center; }
  .about-us .section-title {
    text-align: right;
    padding-right: 85px;
    padding-bottom: 20px; }
  .about-us .text {
    color: #fff;
    padding: 30px 0px 0px 30px;
    margin-bottom: 40px;
    font-size: 17px; }
    .about-us .text span {
      color: #b9d5b7; }
  .about-us .button-services {
    margin-bottom: 20px; }

.benefits {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #eaf9eb; }
  .benefits .items-head {
    background-size: 105% 100%;
    height: 200px;
    position: relative; }
    .benefits .items-head__number {
      font-size: 65px;
      line-height: 65px;
      font-family: Gilroy-ExtraBold, sans-serif;
      position: absolute;
      left: 25px;
      bottom: 70px;
      color: #fff; }
    .benefits .items-head__title {
      font-size: 18px;
      line-height: 18px;
      font-family: Gilroy-ExtraBold, sans-serif;
      position: absolute;
      right: 50px;
      bottom: 80px;
      max-width: 138px; }
  .benefits .items .separator {
    display: none;
    margin-top: auto;
    background-size: 80%;
    background-position-x: 35%;
    background-repeat: no-repeat;
    height: 85px; }
  .benefits .items .item {
    display: flex;
    flex-direction: column; }
    .benefits .items .item p {
      font-family: Gilroy-ExtraBold, sans-serif;
      margin-top: -30px; }
  .benefits .items .item:nth-child(1) .items-head {
    background-image: url(../img/backgrounds/bg-1.png); }
    .benefits .items .item:nth-child(1) .items-head__title {
      color: #82b64a;
      font-size: 24px; }
  .benefits .items .item:nth-child(1) .separator {
    background-image: url(../img/backgrounds/separator-1.png);
    display: block; }
  .benefits .items .item:nth-child(1) p {
    color: #82b64a; }
  .benefits .items .item:nth-child(1)::after {
    content: '';
    background-image: url(../img/backgrounds/separator-4.png);
    background-position: 100% 80%;
    background-size: 75%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 673px; }
  .benefits .items .item:nth-child(2) .items-head {
    background-image: url(../img/backgrounds/bg-2.png); }
    .benefits .items .item:nth-child(2) .items-head__title {
      color: #272727; }
  .benefits .items .item:nth-child(2) .separator {
    background-image: url(../img/backgrounds/separator-2.png);
    display: block; }
  .benefits .items .item:nth-child(2) p {
    color: #272727; }
  .benefits .items .item:nth-child(2)::after {
    content: '';
    background-image: url(../img/backgrounds/separator-5.png);
    background-position: 100% 80%;
    background-size: 75%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 673px; }
  .benefits .items .item:nth-child(3) .items-head {
    background-image: url(../img/backgrounds/bg-3.png); }
    .benefits .items .item:nth-child(3) .items-head__title {
      color: #3b8132; }
  .benefits .items .item:nth-child(3) p {
    color: #3b8132; }
  .benefits .items .item:nth-child(3) .separator {
    background-image: url(../img/backgrounds/separator-3.png);
    display: block; }
  .benefits .items .item:nth-child(4) {
    display: block; }
    .benefits .items .item:nth-child(4) .items-head {
      background-image: url(../img/backgrounds/bg-3.png); }
      .benefits .items .item:nth-child(4) .items-head__title {
        color: #3b8132; }
    .benefits .items .item:nth-child(4) p {
      color: #3b8132; }
  .benefits .items .item:nth-child(5) {
    display: block; }
    .benefits .items .item:nth-child(5) .items-head {
      background-image: url(../img/backgrounds/bg-2.png); }
      .benefits .items .item:nth-child(5) .items-head__title {
        color: #3b8132; }
    .benefits .items .item:nth-child(5) p {
      color: #272727; }
  .benefits .items .item:nth-child(6) {
    display: block; }
    .benefits .items .item:nth-child(6) .items-head {
      background-image: url(../img/backgrounds/bg-1.png); }
      .benefits .items .item:nth-child(6) .items-head__title {
        color: #3b8132; }
    .benefits .items .item:nth-child(6) p {
      color: #82b64a; }

.info {
  padding-top: 20px;
  padding-bottom: 45px;
  background-image: url(../img/bg-info.jpg);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: relative;
  z-index: 9; }
  .info .man {
    margin-top: -50px; }
  .info .items .item {
    padding: 10px 45px;
    background: rgba(108, 171, 64, 0.7);
    border-radius: 10px;
    font-size: 14px;
    font-family: Gilroy-ExtraBold, sans-serif;
    color: #fff;
    height: 47px;
    display: flex;
    align-items: center; }
    .info .items .item:nth-child(2n) {
      border-top: 2px #fff solid;
      border-left: 2px #fff solid;
      border-bottom: 2px #fff solid; }
    .info .items .item:nth-child(2n+1) {
      border-top: 2px #fff solid;
      border-right: 2px #fff solid;
      border-bottom: 2px #fff solid; }
    .info .items .item p {
      margin: 0;
      padding: 0; }
  .info-form {
    text-align: center;
    margin-top: 20px; }
    .info-form .phone {
      background: 0 0;
      border: 2px #fff solid;
      color: #fff;
      padding: 10px 0;
      text-align: center;
      border-radius: 10px;
      font-family: Gilroy-ExtraBold, sans-serif;
      width: 45%;
      /* Firefox 19+ */
      /* Firefox 18- */ }
      .info-form .phone::-webkit-input-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
      .info-form .phone::-moz-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
      .info-form .phone:-moz-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
      .info-form .phone:-ms-input-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
    .info-form .button-consul {
      width: auto; }
    .info-form__text {
      font-size: 20px;
      text-align: center;
      color: #d5ffc1;
      font-family: Gilroy-ExtraBold, sans-serif;
      margin-top: 1rem; }

.seminary {
  background-image: url(../img/bg-seminary.png);
  background-size: cover;
  background-position: 55% center;
  position: relative;
  z-index: 10;
  margin-top: -25px;
  overflow: hidden;
  padding-top: 20px; }
  .seminary-slider__item {
    transform: scale(0.8);
    padding-left: 50px; }
    .seminary-slider__item .title {
      font-size: 24px;
      line-height: 24px;
      color: #3a7f31;
      font-family: Gilroy-ExtraBold, sans-serif;
      opacity: .5; }
    .seminary-slider__item p {
      font-size: 18px;
      line-height: 18px;
      color: #3a7f31;
      font-family: Gilroy-ExtraBold, sans-serif; }
  .seminary-slider .center .seminary-slider__item {
    padding: 0 0 0 20px;
    transform: scale(1); }
    .seminary-slider .center .seminary-slider__item .title {
      font-size: 24px;
      line-height: 24px;
      color: #3a7f31;
      font-family: Gilroy-ExtraBold, sans-serif;
      opacity: .5; }
    .seminary-slider .center .seminary-slider__item p {
      font-size: 18px;
      line-height: 18px;
      color: #3a7f31;
      font-family: Gilroy-ExtraBold, sans-serif; }
  .seminary .owl-nav {
    position: absolute;
    top: calc(50% - 70px);
    right: 0;
    left: 0; }
    .seminary .owl-nav .owl-prev {
      position: absolute;
      left: 31%;
      color: #82b64a;
      opacity: 0.7; }
      .seminary .owl-nav .owl-prev:hover {
        opacity: 1; }
    .seminary .owl-nav .owl-next {
      position: absolute;
      right: 33%;
      color: #82b64a;
      opacity: 0.7; }
      .seminary .owl-nav .owl-next:hover {
        opacity: 1; }
  .seminary .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    margin-left: -25px;
    padding-bottom: 25px; }
    .seminary .owl-dots .owl-dot {
      display: inline-block;
      zoom: 1; }
      .seminary .owl-dots .owl-dot.active {
        zoom: 1.2; }
      .seminary .owl-dots .owl-dot span {
        width: 10px;
        height: 10px;
        margin: 5px 7px;
        background: #82b64a;
        display: block;
        -webkit-backface-visibility: visible;
        -webkit-transition: opacity .2s ease;
        transition: opacity .2s ease;
        border-radius: 30px; }
  .seminary .slider-mockup {
    position: absolute;
    bottom: -30px;
    right: 10px;
    overflow: hidden;
    z-index: 11; }

.consultation {
  background-image: url(../img/bg-consul.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 8;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: -55px; }
  .consultation-form {
    margin-top: 20px; }
    .consultation-form .phone {
      background: 0 0;
      border: 2px #fff solid;
      color: #fff;
      padding: 10px 0;
      border-radius: 10px;
      text-align: center;
      font-family: Gilroy-ExtraBold, sans-serif;
      width: 280px;
      /* Firefox 19+ */
      /* Firefox 18- */ }
      .consultation-form .phone::-webkit-input-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
      .consultation-form .phone::-moz-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
      .consultation-form .phone:-moz-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
      .consultation-form .phone:-ms-input-placeholder {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif; }
    .consultation-form .button-consul {
      width: 280px; }
    .consultation-form__text {
      font-size: 32px;
      line-height: 32px;
      color: #fff;
      font-family: Gilroy-ExtraBold, sans-serif;
      margin-bottom: 1rem; }
    .consultation-form__pre-text {
      font-size: 20px;
      line-height: 20px;
      color: #fff;
      font-family: Gilroy-ExtraBold, sans-serif;
      margin-top: 1rem; }

.reviews {
  padding-top: 30px;
  padding-bottom: 50px;
  background: #eaf9eb;
  position: relative; }
  .reviews .reviews-slider {
    max-width: 80%;
    margin: 0 auto; }
    .reviews .reviews-slider img {
      height: 100%; }
    .reviews .reviews-slider .image-mask {
      height: 280px;
      width: 280px;
      border: 2px #82b64a solid;
      border-radius: 35px;
      overflow: hidden;
      position: relative;
      z-index: 3; }
    .reviews .reviews-slider .item {
      background-color: #e5e5e5;
      border-radius: 35px;
      position: relative;
      margin-top: 20px;
      margin-bottom: 20px;
      box-shadow: 0px 20px 10px -10px rgba(0, 0, 0, 0.75); }
      .reviews .reviews-slider .item .reviews-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        padding: 10px 80px 10px 80px;
        border: 2px #82b64a solid;
        border-radius: 35px;
        z-index: 2;
        background: rgba(255, 255, 255, 0.37); }
        .reviews .reviews-slider .item .reviews-text__name {
          font-size: 30px;
          line-height: 30px;
          color: #272727;
          font-family: Gilroy-ExtraBold, sans-serif; }
        .reviews .reviews-slider .item .reviews-text__date {
          font-size: 16px;
          line-height: 16px;
          font-family: Gilroy-ExtraBold, sans-serif;
          color: #272727; }
        .reviews .reviews-slider .item .reviews-text p {
          margin-top: 1rem; }
      .reviews .reviews-slider .item .button-services {
        width: 195px; }
    .reviews .reviews-slider .slick-next {
      right: 45px;
      top: 60%; }
      .reviews .reviews-slider .slick-next:before {
        content: '▼'; }
    .reviews .reviews-slider .slick-prev {
      right: 45px;
      left: auto;
      top: 40%; }
      .reviews .reviews-slider .slick-prev:before {
        content: '▲'; }
    .reviews .reviews-slider .slick-next, .reviews .reviews-slider .slick-prev {
      z-index: 10; }
      .reviews .reviews-slider .slick-next:before, .reviews .reviews-slider .slick-prev:before {
        color: #70a06a;
        font-size: 35px; }
    .reviews .reviews-slider .slick-dots li button:before {
      font-size: 45px;
      color: #6cab40; }
    .reviews .reviews-slider .slick-dots li.slick-active button:before {
      font-size: 60px;
      color: #4c8c3c; }
  .reviews .mockup-1 {
    width: 195px;
    position: absolute;
    left: 10%;
    bottom: 0; }
  .reviews .mockup-2 {
    width: 140px;
    position: absolute;
    right: 10%;
    bottom: 0; }

.footer {
  background-image: url(../img/bg-footer.png);
  padding-top: 30px;
  position: relative;
  z-index: 9; }
  .footer .content {
    background-color: rgba(39, 39, 39, 0.17);
    margin-bottom: 30px; }
    .footer .content .map {
      border-radius: 35px;
      border: 3px #3a7f31 solid;
      overflow: hidden;
      margin-top: -40px;
      margin-bottom: -40px; }
  .footer .copyright {
    padding-top: 20px;
    padding-bottom: 10px;
    background-color: rgba(39, 39, 39, 0.8);
    color: #fff;
    position: relative;
    z-index: 99; }
    .footer .copyright a {
      color: #fff;
      text-decoration: none; }
  .footer-contact {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    color: #272727;
    font-family: Gilroy-ExtraBold, sans-serif; }
    .footer-contact .item {
      margin-bottom: 10px; }
      .footer-contact .item .dib:first-child {
        min-width: 30px;
        text-align: center; }
      .footer-contact .item .dib:last-child {
        max-width: 200px;
        margin-left: 10px; }
        .footer-contact .item .dib:last-child a {
          color: #272727;
          text-decoration: none; }
      .footer-contact .item.social .dib {
        margin-right: 20px;
        margin-left: 0; }
  .footer-man {
    position: absolute;
    bottom: 0;
    right: 15px;
    height: 110%; }

.owl-carousel .item {
  height: 10rem;
  background: #4DC7A0;
  padding: 1rem; }
  .owl-carousel .item h4 {
    color: #FFF;
    font-weight: 400;
    margin-top: 0rem; }

/**
* Inner-page styles
**/
.inner-page .top-mnu {
  background: #fff; }

.inner-page .section-title.consalting {
  margin-top: 30px;
  text-transform: none; }

.inner-page .section-title.seminary {
  margin-top: 30px;
  margin-bottom: -50px;
  text-transform: none; }

.inner-page .content {
  position: relative; }
  .inner-page .content p {
    color: #464646;
    font-family: Helv-5-Normal, sans-serif; }
    .inner-page .content p span {
      color: #377935; }

.inner-page .consalting-sep {
  width: 100%;
  margin-top: -145px;
  margin-bottom: -60px;
  position: relative;
  z-index: 4; }

.inner-page .cta {
  position: relative;
  background-image: url(../img/bg-cta.jpg);
  background-size: cover;
  background-position: center center;
  z-index: 5;
  margin-bottom: 40px; }

.inner-page .consultation-form {
  margin-top: 200px;
  margin-bottom: 200px; }

.inner-page .seminary {
  margin-top: 0;
  margin-bottom: 40px; }

.inner-page .consalting-sep.seminares {
  margin-bottom: 0; }

.inner-page .seminary-listing {
  margin-bottom: 40px; }
  .inner-page .seminary-listing .card {
    border-radius: 10px;
    background-color: #eaeaea;
    border: #82b150 2px solid;
    height: 100%; }
    .inner-page .seminary-listing .card-body {
      display: flex;
      flex-direction: column; }
    .inner-page .seminary-listing .card-title {
      font-size: 24px;
      line-height: 24px;
      color: #3a7f31;
      text-transform: uppercase;
      font-family: Gilroy-ExtraBold,sans-serif;
      opacity: .5; }
    .inner-page .seminary-listing .card-text {
      font-size: 18px;
      line-height: 18px;
      color: #3a7f31;
      font-family: Gilroy-ExtraBold,sans-serif; }
    .inner-page .seminary-listing .card .separator {
      background: #3a7f31;
      margin: auto auto 5px;
      width: 90%;
      height: 2px; }

.inner-page .about-list .border-list {
  width: calc(98% / 2); }

.inner-page .poleznaya-info .card {
  background: transparent;
  color: #377935;
  margin-bottom: 10px; }
  .inner-page .poleznaya-info .card button {
    color: #377935;
    font-family: Gilroy-ExtraBold, sans-serif;
    white-space: normal; }

.inner-page .consalting-sep.pol-info {
  margin-top: 0; }

.inner-page .reviews-form {
  max-width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px; }
  .inner-page .reviews-form input {
    width: 50%;
    margin: 10px 0; }
  .inner-page .reviews-form .form-control {
    background: #e5e5e5;
    border-radius: 30px;
    padding: 20px;
    border: #82b64a 2px solid;
    outline: -webkit-focus-ring-color auto 0px; }
  .inner-page .reviews-form textarea {
    width: 100%;
    min-height: 300px; }
  .inner-page .reviews-form .button-consul {
    text-align: center;
    border: #82b64a 2px solid; }

.inner-page .lektors {
  max-width: 80%;
  margin: 0 auto; }
  .inner-page .lektors img {
    height: 100%; }
  .inner-page .lektors .image-mask {
    width: 280px;
    height: 280px;
    border: 2px #82b64a solid;
    border-radius: 35px;
    overflow: hidden;
    position: relative;
    text-align: center;
    background: #fff;
    z-index: 3; }
  .inner-page .lektors .item {
    background-color: #4ea74c;
    border-radius: 35px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px; }
    .inner-page .lektors .item .reviews-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 70%;
      padding: 10px 40px 10px 60px;
      border: 2px #82b64a solid;
      border-radius: 35px;
      z-index: 2;
      background: #fff;
      margin-left: auto; }
      .inner-page .lektors .item .reviews-text__name {
        font-size: 18px;
        line-height: 18px;
        color: #4ea74c;
        font-family: Gilroy-ExtraBold, sans-serif;
        text-transform: uppercase; }
      .inner-page .lektors .item .reviews-text__date {
        font-size: 16px;
        line-height: 16px;
        margin-top: 10px;
        font-family: Gilroy-ExtraBold, sans-serif;
        color: #4ea74c; }
      .inner-page .lektors .item .reviews-text p {
        margin-bottom: 0.5rem;
        font-size: 14px; }

.inner-page .consalting-sep.dop-serv {
  margin-top: -130px;
  margin-bottom: 30px; }

.inner-page .dop-serv-list {
  margin-top: 30px;
  list-style-image: url(../img/icons/menu-shield.png); }
  .inner-page .dop-serv-list li {
    margin-bottom: 0.2rem; }

.inner-page .items-dop-serv {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 50px; }
  .inner-page .items-dop-serv .border-list {
    border: 2px #82b64a solid;
    border-radius: 10px;
    width: calc(91% / 2);
    margin: 10px 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
    font-family: Gilroy-ExtraBold, sans-serif; }
  .inner-page .items-dop-serv.width-1 .border-list {
    width: 100%; }

.inner-page .contacts .map {
  border-radius: 35px;
  border: 3px #3a7f31 solid;
  overflow: hidden;
  margin-bottom: 30px; }

.inner-page .contacts .contacts-items {
  flex-direction: column;
  height: 100%;
  color: #272727;
  font-family: Gilroy-ExtraBold, sans-serif; }
  .inner-page .contacts .contacts-items .item {
    margin-bottom: 10px; }
    .inner-page .contacts .contacts-items .item .dib:first-child {
      min-width: 30px;
      text-align: center; }
    .inner-page .contacts .contacts-items .item .dib:last-child {
      max-width: 200px;
      margin-left: 10px; }
      .inner-page .contacts .contacts-items .item .dib:last-child a {
        color: #272727;
        text-decoration: none; }
    .inner-page .contacts .contacts-items .item.social .dib {
      margin-right: 20px;
      margin-left: 0; }

.inner-page .h3, .inner-page .h6 {
  font-family: Gilroy-ExtraBold, sans-serif; }

.mobile-menu {
  display: none; }

.modal-body input.phone {
  background: 0 0;
  border: 2px #377935 solid;
  color: #377935;
  padding: 10px 0;
  text-align: center;
  border-radius: 10px;
  font-family: Gilroy-ExtraBold,sans-serif;
  width: 273px; }

.blog {
  margin-bottom: 5rem; }
  .blog .card strong {
    font-family: Gilroy-ExtraBold, sans-serif; }
    .blog .card strong.author {
      color: #000; }
  .blog .card p {
    color: #000; }
  .blog .card a {
    color: #000; }
    .blog .card a:hover {
      text-decoration: none;
      color: #6cab40; }
  .blog .paging {
    text-align: center; }
    .blog .paging .pageList li {
      display: inline-block; }
      .blog .paging .pageList li a {
        padding: 2px 3px;
        color: #000;
        font-family: Gilroy-ExtraBold, sans-serif; }
        .blog .paging .pageList li a:hover {
          text-decoration: none;
          color: #6cab40; }
  .blog .list-group .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #6cab40;
    border-color: #6cab40; }
  .blog .list-group .list-group-item.disabled {
    font-family: Gilroy-ExtraBold, sans-serif;
    background-color: transparent;
    border-color: #6cab40; }
    .blog .list-group .list-group-item.disabled:hover {
      color: #000;
      background-color: transparent; }
  .blog .list-group .list-group-item .active {
    z-index: 2;
    color: #fff;
    background-color: #6cab40;
    border-color: #6cab40; }
  .blog .list-group .list-group-item:hover {
    z-index: 2;
    color: #fff;
    background-color: #6cab40;
    border-color: #6cab40; }

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .social-search {
    flex-direction: column;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto; }
    .social-search.justify-content-end {
      justify-content: space-between !important; }
    .social-search .item:first-child {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between; }
      .social-search .item:first-child .social {
        margin-right: 0; }
  .services .section-content .coub {
    height: auto; }
  .top-line__contacts {
    flex-wrap: wrap; }
    .top-line__contacts .item {
      max-width: 100%;
      width: 100%; }
  .top-line .logo {
    margin-left: auto; }
  .top-mnu .nav li a {
    font-size: 14px; }
  .services .section-content .coub {
    background-size: 100% 100%;
    padding: 45px 30px 45px 20px;
    width: 100%;
    height: auto; }
  .services .list-services .serv {
    max-height: 245px;
    overflow: scroll; }
    .services .list-services .serv .item {
      width: 100%; }
  .services .ovxh {
    display: none; }
  .about-us {
    padding-top: 90px;
    background-size: cover; }
  .benefits .items-head__title {
    font-size: 16px;
    line-height: 16px;
    right: 25px; }
  .benefits .items .item:nth-child(1) .items-head__title {
    font-size: 20px; }
  .seminary {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 80% 90%; }
    .seminary-slider__item {
      padding-left: 30px; }
      .seminary-slider__item .title {
        font-size: 18px; }
    .seminary-slider .center .seminary-slider__item {
      padding: 0 0 0 15px; }
      .seminary-slider .center .seminary-slider__item .title {
        font-size: 20px; }
    .seminary .slider-mockup {
      bottom: 10px; }
      .seminary .slider-mockup img {
        width: 130px; }
    .seminary .owl-dots {
      margin-left: 0; }
  .reviews .reviews-slider {
    max-width: 100%; }
    .reviews .reviews-slider .item .reviews-text {
      max-width: 100%; }
  .reviews .mockup-1 {
    width: 150px;
    left: 1%; }
  .reviews .mockup-2 {
    right: 5%; }
  .footer {
    overflow-x: hidden; }
    .footer .footer-man {
      right: -50px;
      height: 80%; }
    .footer .map {
      padding-top: -20px; }
  /**
	* inner-page styles
	**/
  .inner-page .section-title.consalting {
    margin-top: 30px;
    margin-bottom: 30px;
    text-transform: none; }
  .inner-page .content {
    margin-bottom: 50px; }
  .inner-page .consalting-sep {
    margin-top: 0;
    margin-bottom: 0;
    display: none; }
  .inner-page .cta {
    background-position: 70% center; }
  .inner-page .reviews-form {
    max-width: 80%; } }

@media only screen and (max-width: 768px) {
  .top-line {
    padding: 20px 0; }
    .top-line .container {
      max-width: 100%;
      flex-wrap: wrap; }
    .top-line__contacts {
      max-width: 210px;
      flex-wrap: wrap; }
    .top-line .logo {
      max-width: 250px; }
      .top-line .logo img {
        width: 100%; }
  .mobile-menu {
    display: block; }
  .top-mnu {
    display: none; }
    .top-mnu .nav {
      flex-direction: column;
      margin: auto; }
      .top-mnu .nav li {
        text-align: center; }
        .top-mnu .nav li a::after {
          display: none; }
  .mobile-menu {
    text-align: center; }
    .mobile-menu .btn-outline {
      background: transparent;
      border: 1px #377935 solid;
      font-family: Gilroy-ExtraBold, sans-serif; }
    .mobile-menu .top-mnu {
      margin-top: 20px;
      display: block;
      background: transparent; }
      .mobile-menu .top-mnu .innerMenu {
        -webkit-padding-start: 0px;
        list-style-type: none;
        background: #fff; }
  .header-title {
    font-size: 28px;
    text-align: right; }
  .header-pre-title {
    font-size: 39px;
    text-align: right; }
  .header-form {
    width: 100%;
    text-align: right; }
  .header {
    background-size: cover;
    background-position: 50% 0;
    padding-top: 70px;
    padding-bottom: 70px; }
  .about-us {
    padding-top: 30px; }
    .about-us .container {
      max-width: 100%; }
      .about-us .container .text {
        font-size: 14px;
        padding: 10px 0 0 0px; }
  .services {
    height: auto; }
  .services .section-content .coub {
    margin-bottom: 50px; }
  .benefits .items .item:nth-child(1)::after {
    display: none; }
  .benefits .items .item:nth-child(2)::after {
    display: none; }
  .benefits .items .item:nth-child(4) {
    display: flex; }
    .benefits .items .item:nth-child(4) .separator {
      background-image: url(../img/backgrounds/separator-3.png);
      display: block; }
  .benefits .items-head__title {
    right: 45px; }
  .info img.w-100 {
    width: auto !important;
    margin-left: auto;
    margin-right: auto; }
  .info .section-title {
    text-align: center; }
  .seminary {
    background-size: cover;
    background-position: 75% 90%; }
  .seminary-slider .owl-item.active .seminary-slider__item {
    padding: 0 0 0 20px;
    transform: scale(1); }
    .seminary-slider .owl-item.active .seminary-slider__item .title {
      font-size: 24px;
      line-height: 24px;
      color: #3a7f31;
      font-family: Gilroy-ExtraBold, sans-serif;
      opacity: .5; }
    .seminary-slider .owl-item.active .seminary-slider__item p {
      font-size: 18px;
      line-height: 18px;
      color: #3a7f31;
      font-family: Gilroy-ExtraBold, sans-serif; }
  .seminary-slider .owl-item.active + .owl-item.active .seminary-slider__item {
    transform: scale(0.8);
    padding-left: 50px; }
    .seminary-slider .owl-item.active + .owl-item.active .seminary-slider__item .title {
      font-size: 24px;
      line-height: 24px;
      color: #3a7f31;
      font-family: Gilroy-ExtraBold, sans-serif;
      opacity: .5; }
    .seminary-slider .owl-item.active + .owl-item.active .seminary-slider__item p {
      font-size: 18px;
      line-height: 18px;
      color: #3a7f31;
      font-family: Gilroy-ExtraBold, sans-serif; }
  .seminary-slider .owl-nav .owl-prev {
    left: -15px; }
  .seminary-slider .owl-nav .owl-next {
    left: 48%; }
  .seminary-slider .owl-dots {
    text-align: left;
    margin-left: 20%; }
  .consultation-form__text {
    font-size: 25px;
    line-height: 25px; }
  .reviews .reviews-slider .image-mask {
    width: 220px;
    height: 220px;
    margin-top: 30px;
    margin-bottom: 30px; }
  .reviews .reviews-slider .reviews-text__name {
    display: inline-block; }
  .reviews .reviews-slider .reviews-text__date {
    display: inline-block; }
  .reviews .reviews-slider .reviews-text p {
    font-size: 14px; }
  .inner-page .poleznaya-info .card {
    text-align: center; }
  .inner-page .poleznaya-info .blog .card {
    text-align: left; } }

@media only screen and (max-width: 576px) {
  .top-line {
    padding: 0; }
    .top-line .container {
      flex-wrap: wrap; }
    .top-line__contacts {
      max-width: 100%;
      margin: 10px auto; }
    .top-line .logo {
      margin: 10px auto; }
    .top-line .social-search {
      margin: 10px auto; }
  .header {
    background-position: 100% 0; }
    .header-title {
      text-align: center; }
    .header-pre-title {
      text-align: center;
      color: #eaf9eb; }
    .header-form {
      text-align: center; }
  .services {
    height: auto; }
    .services .section-title.dark {
      text-align: center; }
      .services .section-title.dark span {
        margin-left: 0; }
    .services .section-content .list-services .serv {
      max-height: 100%; }
    .services .section-content .coub {
      padding: 30px 30px 50px 30px;
      margin-right: auto;
      margin-bottom: 0; }
  .about-us {
    background-position: left center; }
    .about-us .section-title {
      text-align: center; }
    .about-us .text-right {
      text-align: center !important; }
  .benefits .items .item {
    margin-bottom: 20px; }
    .benefits .items .item:nth-child(5) .separator {
      background-image: url(../img/backgrounds/separator-2.png);
      display: block; }
  .info img.w-100 {
    width: 100% !important; }
  .seminary {
    background-image: url(../img/bg-seminary-sm.png);
    background-position: center center;
    background-size: 100% 100%; }
    .seminary-slider .owl-nav .owl-prev {
      left: 0; }
    .seminary-slider .owl-nav .owl-next {
      right: 0;
      left: auto; }
    .seminary-slider .owl-item.active .seminary-slider__item {
      padding: 0 20px; }
    .seminary-slider .owl-dots {
      text-align: center;
      margin-left: auto;
      margin-right: auto; }
  .consultation {
    background-position: 68% center; }
    .consultation-form {
      text-align: center; }
  .reviews .reviews-slider .item {
    display: block !important; }
    .reviews .reviews-slider .item .image-mask {
      width: 80%;
      height: 80%;
      margin: auto;
      display: none; }
    .reviews .reviews-slider .item .reviews-text {
      max-width: 100%;
      position: relative;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      align-text: justify;
      padding: 20px 40px 20px 20px; }
  .reviews .reviews-slider .slick-prev {
    right: 25px; }
  .reviews .reviews-slider .slick-next {
    right: 25px; }
  .reviews .mockup-1 {
    display: none; }
  .reviews .mockup-2 {
    display: none; }
  .footer .content .map {
    margin-bottom: 20px;
    margin-top: 20px; }
  .footer .content .footer-contact {
    align-items: center; }
  .footer .footer-man {
    display: none; }
  .info .items .item {
    height: auto; }
  .inner-page .items-dop-serv .border-list {
    width: calc(96% / 2); }
  .inner-page .reviews-form {
    max-width: 100%; } }

@media only screen and (max-width: 480px) {
  .inner-page .items-dop-serv .border-list {
    width: 100%;
    margin: 10px auto; } }
